export default {
    prop_floor: '',
    achievement: {
        prop_current_price: '',
        status: '1',
        stages: [{
          id: '',
          details: '', // تفاصيل المرحلة
          cost: '', // التكلفه
          pc_to_cost: '', // النسبة إلى التكلفه
          pc_of_completion: '', // نسبة الانجاز
          achievement_value: '',
        }],
      },

    id: 2,
    transaction_id: '20211220462566',
    trans_number: null,
    trans_uuid: null,
    instrument_files: [],
    assignment_letter_files: [],
    attached_file: { original_url: '' },
    schema_file: null,
    assignment_letter_file: null,
    resident_id: 3,
    reviewer_id: 4,
    approved_id: 4,
    customer_id: 4,
    sample_id: 1,
    trans_report_name: 'test',
    trans_report_phone: 'test',
    evaluation_purpose_id: 3,
    trans_value_basis: null,
    value_hypothesis: null,
    trans_assignment_date: '2021-12-20',
    trans_evaluation_date: '2021-12-20',
    trans_inspection_date: '2021-12-20',
    trans_Report_type: '3',
    trans_filing_the_report: '123',
    trans_reference_number: '123',
    trans_deposit_code_site_num_customer_num: '123',
    region_id: 1,
    city_id: 9,
    neighborhood_id: 5,
    trans_street_name: 'tss',
    residential_plan_name: 'tset',
    residential_plan_no: '213',
    trans_Albulk_num: '213',
    trans_part_num: '123',
    trans_owner_name: 'test',
    trans_owner_phone: '21',
    property_type_id: 3,
    property_rating_id: 2,
    trans_instrument_num: '13',
    trans_instrument_date: '2021-12-20',
    trans_building_permit_number: '213',
    trans_building_permit_date: '2021-12-20T00:00:00.000000Z',
    trans_construction_age: 123,
    trans_retail_record_num: '123',
    trans_retail_record_date: '2021-12-20T00:00:00.000000Z',
    trans_construction_condition: null,
    trans_occupancy_status: null,
    trans_general_site: null,
    trans_the_design: null,
    trans_attributable: null,
    trans_neighbor: null,
    trans_streets: null,
    trans_Street_lighting: null,
    trans_electric_current: null,
    trans_insulation_type: null,
    trans_the_type_of_yard_floor: null,
    trans_reception_floor_type: null,
    trans_entrance_floor: null,
    trans_room_floor_type: null,
    trans_bolt_the_outer_doors: null,
    trans_wool_interior_doors: null,
    trans_structural_structure: null,
    trans_bishop_type: null,
    trans_boards: null,
    trans_dining_rooms: null,
    trans_bed_room: null,
    trans_kitchens: null,
    trans_toilets: null,
    trans_jacuzzi: null,
    trans_garden: null,
    trans_maids_room: null,
    trans_guard_Room: null,
    trans_basement: null,
    trans_halls: null,
    trans_supplements: null,
    trans_elevators: null,
    trans_parking: null,
    trans_children_playground: null,
    trans_swimming_pool: null,
    trans_storehouse: null,
    glass_walls: null,
    central_conditioning: null,
    double_glazing: null,
    plaster_on_the_ceiling: null,
    hidden_lighting: null,
    arabic_bath: null,
    stairs: null,
    electric_garage: null,
    ordinary_garage: null,
    portals: null,
    heaters: null,
    french_bath: null,
    trans_boards_status: null,
    trans_dining_rooms_status: null,
    trans_bed_room_status: null,
    trans_kitchens_status: null,
    trans_toilets_status: null,
    trans_jacuzzi_status: null,
    trans_garden_status: null,
    trans_maids_room_status: null,
    trans_guard_Room_status: null,
    trans_basement_status: null,
    trans_halls_status: null,
    trans_supplements_status: null,
    trans_elevators_status: null,
    trans_parking_status: null,
    trans_children_playground_status: null,
    trans_swimming_pool_status: null,
    trans_storehouse_status: null,
    glass_walls_status: null,
    central_conditioning_status: null,
    double_glazing_status: null,
    plaster_on_the_ceiling_status: null,
    hidden_lighting_status: null,
    arabic_bath_status: null,
    stairs_status: null,
    electric_garage_status: null,
    ordinary_garage_status: null,
    portals_status: null,
    heaters_status: null,
    french_bath_status: null,
    trans_other_component: null,
    additional_component_1: null,
    additional_component_2: null,
    additional_component_3: null,
    additional_component_4: null,
    additional_component_value_1: null,
    additional_component_value_2: null,
    additional_component_value_3: null,
    additional_component_value_4: null,
    finishing_condition: null,
    latitude: null,
    longitude: null,
    coordinate_type: '1',
    property_notes: null,
    property_condition: null,
    construction_condition: null,
    occupancy_status: null,
    northern_border: null,
    eastern_border: null,
    western_border: null,
    southern_border: null,
    tall_northern: null,
    tall_eastern: null,
    tall_western: null,
    tall_southern: null,
    north_facade: null,
    eastern_facade: null,
    western_facade: null,
    south_facade: null,
    masjid: null,
    masjid_note: null,
    masjid_distance: null,
    market: null,
    market_note: null,
    market_distance: null,
    medical_facility: null,
    medical_facility_note: null,
    medical_facility_distance: null,
    security_facility: null,
    security_facility_note: null,
    security_facility_distance: null,
    public_garden: null,
    public_garden_note: null,
    public_garden_distance: null,
    educational_facility: null,
    educational_facility_note: null,
    educational_facility_distance: null,
    government_office: null,
    government_office_note: null,
    government_office_distance: null,
    highway: null,
    highway_note: null,
    highway_distance: null,
    is_telephone: null,
    water_meter: null,
    water_meter_number: [],
    electric_meter: null,
    electric_meter_number: [],
    sanitation: null,
    resident_opinion: null,
    scope_research_sources_information: null,
    unfinished_n_maintenance: null,
    notes_shortcomings: null,
    important_notes: null,
    private_notes: null,
    reason_for_comment: null,
    recommendation: null,
    acknowledgment_independence: null,
    land_area: null,
    construction_age: null,
    basement_space: null,
    annexes_space: null,
    num_floors_residential_villas: null,
    conforms_building_permit: null,
    preview_limits: null,
    review_note: null,
    trans_finishing_status: null,
    trans_finishing_internal: null,
    trans_finishing_external: null,
    cm_land_space: null,
    cm_land_price: null,
    cm_land_s_p_total: null,
    cm_building_space: null,
    cm_building_price: null,
    cm_building_s_p_total: null,
    cm_basement_space: null,
    cm_basement_price: null,
    cm_basement_s_p_total: null,
    cm_supplement_space: null,
    cm_supplement_price: null,
    cm_supplement_s_p_total: null,
    cm_fences_space: null,
    cm_fences_price: null,
    cm_fences_s_p_total: null,
    cm_other_title: null,
    cm_other_space: null,
    cm_other_price: null,
    cm_other_s_p_total: null,
    cm_method_total: null,
    cm_exchange_note: null,
    cm_exchange_value: null,
    cm_direct_costs_note: null,
    cm_direct_costs: null,
    cm_indirect_costs_note: null,
    cm_indirect_costs: null,
    cm_physical_deterioration_ratio: null,
    cm_physical_deterioration_value: null,
    cm_occupational_limitations_ratio: null,
    cm_occupational_limitations_value: null,
    cm_economic_obsolescence_ratio: null,
    cm_economic_obsolescence_value: null,
    cm_total_depreciation_ratio: null,
    cm_total_depreciation_value: null,
    cm_depreciation_buildings_ratio: null,
    cm_depreciation_buildings_value: null,
    cm_depreciation_s_business_ratio: null,
    cm_depreciation_s_business_value: null,
    cm_developer_earnings_ratio: null,
    cm_developer_earnings_value: null,
    cm_total_market_value: null,
    weights_market_value_relative_weights_roads_used: null,
    relative_market_value_relative_weights_roads_used: null,
    weights_market_value_income_capitalization: null,
    relative_market_value_income_capitalization: null,
    weights_market_value_cost: null,
    relative_market_value_cost: null,
    total_weights: null,
    market_value_weighting_number: null,
    market_value_weighting_text: null,
    cm_space: null,
    cm_operation_type: null,
    operation_date: '2021-12-20T00:00:00.000000Z',
    cm_price: null,
    cm_type: null,
    cm_mobile_number: null,
    cm_latitude: null,
    cm_longitude: null,
    cm_space_2: null,
    cm_operation_type_2: null,
    operation_date_2: '2021-12-20',
    cm_price_2: null,
    cm_type_2: null,
    cm_mobile_number_2: null,
    cm_latitude_2: null,
    cm_longitude_2: null,
    cm_space_3: null,
    cm_operation_type_3: null,
    operation_date_3: '2021-12-20T00:00:00.000000Z',
    cm_price_3: null,
    cm_type_3: null,
    cm_mobile_number_3: null,
    cm_latitude_3: null,
    cm_longitude_3: null,
    cm_time_factor_adjustment: null,
    cm_time_factor_adjustment_2: null,
    cm_time_factor_adjustment_3: null,
    cm_settlement_financing_terms: null,
    cm_settlement_financing_terms_2: null,
    cm_settlement_financing_terms_3: null,
    cm_settling_market_conditions: null,
    cm_settling_market_conditions_2: null,
    cm_settling_market_conditions_3: null,
    cm_total_funding_market_adjustments: null,
    cm_total_funding_market_adjustments_2: null,
    cm_total_funding_market_adjustments_3: null,
    cm_price_after_settling_financing_terms: null,
    cm_price_after_settling_financing_terms2: null,
    cm_price_after_settling_financing_terms3: null,
    cm_space_settlement: null,
    cm_space_settlement2: null,
    cm_space_settlement3: null,
    cm_other_settlement_title: null,
    cm_other_settlement: null,
    cm_other_settlement2: null,
    cm_other_settlement3: null,
    cm_total_settlement: null,
    cm_total_settlement2: null,
    cm_total_settlement3: null,
    cm_selling_p_a_settlement: null,
    cm_selling_p_a_settlement2: null,
    cm_selling_p_a_settlement3: null,
    cm_relative_w_comparable_p: null,
    cm_relative_w_comparable_p2: null,
    cm_relative_w_comparable_p3: null,
    cm_contribution_comparative_p_relative_weight: null,
    cm_contribution_comparative_p_relative_weight2: null,
    cm_contribution_comparative_p_relative_weight3: null,
    cm_market_v_comparative_sales_method: null,
    cs_settlement_table: null,
    unit_name: null,
    rent_units: null,
    unit_number: null,
    shaghayir_maintenance: null,
    total_rent: null,
    net_income: null,
    unit_name2: null,
    rent_units2: null,
    unit_number2: null,
    shaghayir_maintenance2: null,
    total_rent2: null,
    net_income2: null,
    unit_name3: null,
    rent_units3: null,
    unit_number3: null,
    shaghayir_maintenance3: null,
    total_rent3: null,
    net_income3: null,
    total_annual_income: null,
    deduction_losses: null,
    total_actual_income: null,
    m_operating_c_expenditures: null,
    net_operating_income: null,
    capitalization_rate: null,
    market_v_income_c_method: null,
    trans_report_price: null,
    trans_name_subscriber_in_report: null,
    trans_commissions_percentage: null,
    trans_commissions_amount: null,
    trans_internal_notes: null,
    trans_currency: null,
    trans_professional_standard: null,
    trans_restrictions_publication: null,
    trans_evacuation_responsibility: null,
    trans_special_assumptions: null,
    status: 1,
    add_by: 3,
    created_at: '2021-12-20T11:03:29.000000Z',
    updated_at: '2021-12-20T11:03:29.000000Z',
    facilities_id: null,
    sample: {
        id: 1,
        name: 'النموذج القياسي',
        status: '1',
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
    },
    reviewer: {
        id: 4,
        name: 'نهاد الشهري',
        image: 'upload/users/images/default.png',
        email: 'rshami@example.net',
        two_factor_secret: null,
        two_factor_recovery_codes: null,
        role_id: null,
        user_type: 5,
        status: '1',
        created_at: '2021-12-19T10:13:30.000000Z',
        updated_at: '2021-12-19T10:13:30.000000Z',
        username: null,
        phone: null,
        id_number: null,
        beneficiary_name: null,
        bank_number: null,
        bank_IBAN: null,
        bank_statement_image: null,
        start_membership: null,
        end_membership: null,
        commission_input_stage_rate: null,
        commission_input_stage_amount: null,
        commission_evaluation_stage_rate: null,
        commission_evaluation_stage_amount: null,
        commission_revision_stage_rate: null,
        commission_revision_stage_amount: null,
        commission_accreditation_stage_rate: null,
        commission_accreditation_stage_amount: null,
        facilities_id: null,
        role: null,
    },
    resident: {
        id: 3,
        name: 'Omer',
        image: 'upload/users/images/default.png',
        email: 'omer@gmail.com',
        two_factor_secret: null,
        two_factor_recovery_codes: null,
        role_id: null,
        user_type: 2,
        status: '1',
        created_at: '2021-12-19T10:13:30.000000Z',
        updated_at: '2021-12-19T10:13:30.000000Z',
        username: null,
        phone: null,
        id_number: null,
        beneficiary_name: null,
        bank_number: null,
        bank_IBAN: null,
        bank_statement_image: null,
        start_membership: null,
        end_membership: null,
        commission_input_stage_rate: null,
        commission_input_stage_amount: null,
        commission_evaluation_stage_rate: null,
        commission_evaluation_stage_amount: null,
        commission_revision_stage_rate: null,
        commission_revision_stage_amount: null,
        commission_accreditation_stage_rate: null,
        commission_accreditation_stage_amount: null,
        facilities_id: null,
        role: null,
    },
    region: {
        id: 1,
        name: 'الثقبة',
        status: '1',
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
    },
    propertytype: {
        id: 3,
        name: 'maiores',
        status: '1',
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
        deleted_at: null,
    },
    propertyrating: {
        id: 2,
        name: 'officia',
        status: '2',
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
        deleted_at: null,
    },
    neighborhood: {
        id: 5,
        name: 'facilis',
        city_id: 9,
        status: '1',
        created_at: '1978-08-14T07:48:38.000000Z',
        updated_at: '2009-01-27T06:29:04.000000Z',
    },
    addby: {
        id: 3,
        name: 'Omer',
        image: 'upload/users/images/default.png',
        email: 'omer@gmail.com',
        two_factor_secret: null,
        two_factor_recovery_codes: null,
        role_id: null,
        user_type: 2,
        status: '1',
        created_at: '2021-12-19T10:13:30.000000Z',
        updated_at: '2021-12-19T10:13:30.000000Z',
        username: null,
        phone: null,
        id_number: null,
        beneficiary_name: null,
        bank_number: null,
        bank_IBAN: null,
        bank_statement_image: null,
        start_membership: null,
        end_membership: null,
        commission_input_stage_rate: null,
        commission_input_stage_amount: null,
        commission_evaluation_stage_rate: null,
        commission_evaluation_stage_amount: null,
        commission_revision_stage_rate: null,
        commission_revision_stage_amount: null,
        commission_accreditation_stage_rate: null,
        commission_accreditation_stage_amount: null,
        facilities_id: null,
        role: null,
    },
    incomevaluation: [],
    evaluationpurpose: {
        id: 3,
        name: 'corrupti',
        status: '2',
        created_at: '2021-11-20T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
        deleted_at: null,
    },
    customer: {
        id: 4,
        cs_name: 'الآنسة ريتا الخالدي',
        cs_phone: '+1-978-487-2151',
        cs_email: 'hamad.bilal@abbas.net',
        cs_email_cc: 'yazan.qawasmee@abbas.net',
        sample_id: 1,
        cs_vat_num: '217',
        cs_logo: 'https://via.placeholder.com/200x200.png/009999?text=nobis',
        report_id: 3,
        show_logo: '1',
        cs_data_fount_color: null,
        cs_data_frame_color: null,
        cs_data_background_color: null,
        cs_subdata_fount_color: null,
        cs_subdata_frame_color: null,
        cs_subdata_background_color: null,
        image_per_page: '6',
        date_time_show: '2',
        map_show: '2',
        input_stage_sign_show: '2',
        evaluation_stage_sign_show: '2',
        review_stage_sign_show: '2',
        adoption_stage_sign_show: '2',
        facility_stamp_show: '2',
        input_stage_name_show: null,
        evaluation_stage_name_show: null,
        review_stage_name_show: null,
        adoption_stage_name_show: null,
        facility_stamp_name: null,
        land_default_price: null,
        building_default_price: null,
        cs_type: 1,
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
        status: '1',
        facilities_id: null,
        deleted_at: null,
    },
    city: {
        id: 9,
        name: 'الثقبة',
        region_id: 1,
        status: '1',
        created_at: '2021-12-19T10:13:33.000000Z',
        updated_at: '2021-12-19T10:13:33.000000Z',
        neighborhoods: [
            {
                id: 5,
                name: 'facilis',
                city_id: 9,
                status: '1',
                created_at: '1978-08-14T07:48:38.000000Z',
                updated_at: '2009-01-27T06:29:04.000000Z',
            },
        ],
        region: {
            id: 1,
            name: 'الثقبة',
            status: '1',
            created_at: '2021-12-19T10:13:33.000000Z',
            updated_at: '2021-12-19T10:13:33.000000Z',
        },
    },
    approved: {
        id: 4,
        name: 'نهاد الشهري',
        image: 'upload/users/images/default.png',
        email: 'rshami@example.net',
        two_factor_secret: null,
        two_factor_recovery_codes: null,
        role_id: null,
        user_type: 5,
        status: '1',
        created_at: '2021-12-19T10:13:30.000000Z',
        updated_at: '2021-12-19T10:13:30.000000Z',
        username: null,
        phone: null,
        id_number: null,
        beneficiary_name: null,
        bank_number: null,
        bank_IBAN: null,
        bank_statement_image: null,
        start_membership: null,
        end_membership: null,
        commission_input_stage_rate: null,
        commission_input_stage_amount: null,
        commission_evaluation_stage_rate: null,
        commission_evaluation_stage_amount: null,
        commission_revision_stage_rate: null,
        commission_revision_stage_amount: null,
        commission_accreditation_stage_rate: null,
        commission_accreditation_stage_amount: null,
        facilities_id: null,
        role: null,
    },
    facility: {
        id: 5,
        name: null,
        logo: null,
        url: null,
        tax_number: null,
        VAT_rate: null,
        seal_name: null,
        seal_url: null,
        resident_name: 'أحمد الورداني',
        evaluation_branch: null,
        membership_category: null,
        resident_adjective: null,
        signature: null,
        license_number: '1234567',
        license_date: '2021-12-07',
        phone_number: null,
        email: null,
        unified_number: null,
        ID_approved_resident: null,
        national_address: null,
        city: null,
        neighborhood: null,
        street: null,
        building_number: null,
        unit_number: null,
        postal_code: null,
        extra_number: null,
        user_id: 3,
        status: '1',
        created_at: '2021-12-21T20:40:34.000000Z',
        updated_at: '2021-12-21T21:22:47.000000Z',
        deleted_at: null,
        user: {
            id: 3,
            name: 'Omer',
            image: 'upload/users/images/default.png',
            email: 'omer@gmail.com',
            two_factor_secret: null,
            two_factor_recovery_codes: null,
            role_id: null,
            user_type: 2,
            status: '1',
            created_at: '2021-12-19T10:13:30.000000Z',
            updated_at: '2021-12-19T10:13:30.000000Z',
            username: null,
            phone: null,
            id_number: null,
            beneficiary_name: null,
            bank_number: null,
            bank_IBAN: null,
            bank_statement_image: null,
            start_membership: null,
            end_membership: null,
            commission_input_stage_rate: null,
            commission_input_stage_amount: null,
            commission_evaluation_stage_rate: null,
            commission_evaluation_stage_amount: null,
            commission_revision_stage_rate: null,
            commission_revision_stage_amount: null,
            commission_accreditation_stage_rate: null,
            commission_accreditation_stage_amount: null,
            facilities_id: null,
            role: null,
        },
    },
}
