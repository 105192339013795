<template>
  <v-container>
    <v-card class="form-container">
      <v-card-title
        justify="space-between"
        align="center"
      >
        {{ this.$route.name === 'Treatments' ? 'المعاملات' : 'معاملاتي' }}
        <v-spacer />
        <div
          v-if="this.$route.name === 'Treatments'"
          class="text-end"
        >
          <v-dialog
            v-model="dialog"
            max-width="800px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                class="mx-2 filter-bg"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  fas fa-filter
                </v-icon>
                فرز
              </v-btn>
            </template>
            <v-card>
              <div style="text-align:left">
                <v-btn
                  id="cancel-filter"
                  icon
                  text
                  style="margin-left:20px"
                  large
                  @click="dialog=false"
                >
                  <i class="v-icon notranslate v-icon--right mdi mdi-close theme--dark" />
                </v-btn>
              </div>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <label class="d-block mb-3 font-weight-bold">العميل</label>
                      <v-autocomplete
                        v-model="data.customer_id"
                        :items="customersList"
                        label="العميل"
                        item-text="name"
                        item-value="id"
                        single-line
                        outlined
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="5"
                    >
                      <label class="d-block mb-3 font-weight-bold">من</label>
                      <v-menu
                        v-model="menu1"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-x
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="data.dateFrom"
                            clearable
                            placeholder="YYYY-MM-DD Format"
                            hint="YYYY-MM-DD Format"
                            v-bind="attrs"
                            single-line
                            outlined
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="data.dateFrom"
                          @input="menu1 = false"
                        />
                      </v-menu>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="2"
                      class="d-flex align-center justify-center"
                    >
                      <v-icon>mdi-less-than</v-icon>
                    </v-col>
                    <v-col
                      cols="12"
                      sm="12"
                      md="5"
                    >
                      <label class="d-block mb-3 font-weight-bold">إلى</label>
                      <v-menu
                        v-model="menu2"
                        :close-on-content-click="false"
                        transition="scale-transition"
                        offset-x
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="data.dateTo"
                            clearable
                            placeholder="YYYY-MM-DD Format"
                            hint="YYYY-MM-DD Format"
                            v-bind="attrs"
                            single-line
                            outlined
                            v-on="on"
                          />
                        </template>
                        <v-date-picker
                          v-model="data.dateTo"
                          @input="menu2 = false"
                        />
                      </v-menu>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      lg="3"
                      md="4"
                    >
                      <label class="d-block font-weight-bold">فرز حسب</label>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <!-- <div class="mr-3">
                      <v-checkbox
                        v-model="data.filterBy['1']"
                        class="check-label"
                        label="تاريخ الإرسال"
                        color="success"
                        value=""
                        hide-details
                      />
                    </div> -->
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.order_by"
                        class="check-label"
                        label="تاريخ الإدخال"
                        color="success"
                        value="created_at"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.order_by"
                        class="check-label"
                        label="تاريخ التكليف"
                        color="success"
                        value="trans_assignment_date"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.order_by"
                        class="check-label"
                        label="تاريخ التقييم"
                        color="success"
                        value="trans_evaluation_date"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.order_by"
                        class="check-label"
                        label="تاريخ المعاينة"
                        color="success"
                        value="trans_inspection_date"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.order_by"
                        class="check-label"
                        label="ناريخ الاعتماد"
                        color="success"
                        value="trans_approvition_date"
                        hide-details
                      />
                    </div>
                  </v-row>
                  <v-row class="mt-10">
                    <v-col cols="12">
                      <label class="d-block font-weight-bold">رقم الصك</label>
                      <v-text-field
                        v-model="data.trans_instrument_num"
                        single-line
                        outlined
                      />
                    </v-col>
                  </v-row>
                  <v-row class="mt-10">
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">المدخل</label>
                      <v-autocomplete
                        v-model="data.add_by"
                        :items="usersList"
                        item-text="name"
                        item-value="id"
                        label="المدخل"
                        single-line
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">المقيم</label>
                      <v-autocomplete
                        v-model="data.resident_id"
                        :items="usersList"
                        item-text="name"
                        item-value="id"
                        label="المقيم"
                        single-line
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">المراجع</label>
                      <v-autocomplete
                        v-model="data.reviewer_id"
                        :items="usersList"
                        item-text="name"
                        item-value="id"
                        label="المراجع"
                        single-line
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">المعمد</label>
                      <v-autocomplete
                        v-model="data.approved_id"
                        :items="usersList"
                        item-text="name"
                        item-value="id"
                        label="المعمد"
                        single-line
                        outlined
                        clearable
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">الغرض من التقييم</label>
                      <v-autocomplete
                        v-model="data.evaluation_purpose_id"
                        :items="evaluationPurposeList"
                        label="الغرض من التقييم"
                        item-text="name"
                        item-value="id"
                        single-line
                        outlined
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">نوع العقار</label>
                      <v-autocomplete
                        v-model="data.property_type_id"
                        :items="propTypeList"
                        item-text="name"
                        item-value="id"
                        label="نوع العقار"
                        single-line
                        outlined
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      md="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">تصنيف العقار</label>
                      <v-autocomplete
                        v-model="data.property_rating_id"
                        :items="propRatingsList"
                        item-text="name"
                        item-value="id"
                        label="تصنيف العقار"
                        single-line
                        outlined
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">المنطقة</label>
                      <v-autocomplete
                        v-model="data.region_id"
                        :items="regionsList"
                        label="المنطقة"
                        single-line
                        outlined
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">اسم المدينة</label>
                      <v-autocomplete
                        v-model="data.city_id"
                        :items="updateCitesList"
                        label="اسم المدينة"
                        single-line
                        outlined
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                    <v-col
                      cols="12"
                      lg="6"
                    >
                      <label class="d-block mb-3 font-weight-bold">اسم الحى</label>
                      <v-autocomplete
                        v-model="data.neighborhood_id"
                        :items="updateNeighborhoodsList"
                        label="اسم الحى"
                        single-line
                        outlined
                        item-text="name"
                        item-value="id"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col
                      cols="12"
                      lg="3"
                      md="4"
                    >
                      <label class="d-block font-weight-bold">حالة المعاملة</label>
                    </v-col>
                  </v-row>
                  <v-row class="mt-0">
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="مسودة"
                        color="success"
                        :value="1"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="تحت التقييم"
                        color="success"
                        :value="2"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="تحت المراجعة"
                        color="success"
                        :value="3"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="قيد الإعتماد"
                        color="success"
                        :value="4"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="معتمدة"
                        color="success"
                        :value="5"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="معلقة"
                        color="success"
                        :value="7"
                        hide-details
                      />
                    </div>
                    <div class="mr-3">
                      <v-checkbox
                        v-model="data.status"
                        class="check-label"
                        label="ملغية"
                        color="success"
                        :value="8"
                        hide-details
                      />
                    </div>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions class="justify-center">
                <v-btn
                  x-large
                  class="ma-2"
                  color="blue"
                  @click="dialog = false"
                >
                  <v-icon left>
                    mdi-file
                  </v-icon>
                  قائمة المعاملات
                </v-btn>
                <v-btn
                  x-large
                  class="ma-2 filter-bg"
                  @click="filterTransctions();fetchAllItems(data);dialog=false"
                >
                  <v-icon left>
                    fas fa-filter
                  </v-icon>
                  فرز
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <select-sample v-if="permissons.create_transaction.add" />
          <!-- <v-dialog
            v-model="newTratment"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                x-large
                class="ma-2"
                color="blue"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left>
                  mdi-file-plus-outline
                </v-icon>
                معاملة جديدة
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-right">
                <h3 class="card-title">
                  اختيار نوع النموذج
                </h3>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col
                      cols="12"
                      sm="12"
                    >
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            single-line
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          >
                            اختر النموذج
                          </v-btn>
                        </template>
                        <v-list>
                          <template v-for="sample in samplesList">
                            <v-list-item
                              v-show="sample.status === '1'"
                              :key="sample.id"
                            >
                              <v-list-item-title>
                                <router-link :to="sample.path">
                                  {{ sample.name }}
                                </router-link>
                              </v-list-item-title>
                            </v-list-item>
                          </template>
                        </v-list>
                      </v-menu>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
            </v-card>
          </v-dialog> -->
        </div>
      </v-card-title>
      <template>
        <v-data-table
          :loading="isLoading"
          :headers="headers"
          :items="itemsTr"
          :items-per-page="20"
          :options.sync="options"
          :server-items-length="total"
          class="elevation-1"
        >
          <template v-slot:[`item.transaction_id`]="{ item }">
            <router-link
              :to="{
                name: getSampleComponent(item),
                params: {id: item.id},
                query: item.status !== 1 && item.statusWhenSuspended !== 1 ? {} : {sample_id: item.sample_id}
              }"
              class="number_link"
            >
              {{ item.transaction_id }}
            </router-link>
          </template>
          <template v-slot:[`item.status`]="{ item }">
            <v-chip
              class="pa-2"
              color="yellow"
            >
              {{ statuses[item.status] }}
            </v-chip>
          </template>
          <template v-slot:[`item.action`]="{item}">
            <template>
              <div class="text-center">
                <v-menu offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      fas fa-ellipsis-v
                    </v-icon>
                  </template>
                  <v-list>
                    <v-list-item>
                      <v-list-item-title>
                        <a
                          target="_blank"
                          :href="'https://development.millennium.sa/api/v1/generate-PDF-sample-file?transaction_id='+item.id"
                        >
                          <v-icon>
                            far fa-file-pdf
                          </v-icon>
                          تنزيل PDF
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title>
                        <router-link :to="'/New-Treatment/7?edit=' + item.id">
                          <v-icon>
                            far fa-edit
                          </v-icon>
                          تعديل
                        </router-link>
                      </v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item>
                      <v-list-item-title>
                        <router-link to="/">
                          <v-icon>
                            far fa-eye
                          </v-icon>
                          معاينة
                        </router-link>
                      </v-list-item-title>
                    </v-list-item> -->
                    <!-- <v-list-item>
                      <v-list-item-title>
                        <router-link to="/">
                          <v-icon>
                            fas fa-user-clock
                          </v-icon>
                          حجز موعد
                        </router-link>
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item>
                      <v-list-item-title>
                        <router-link to="/">
                          <v-icon>
                            far fa-file-alt
                          </v-icon>
                          الفاتورة
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <!-- <v-list-item>
                      <v-list-item-title>
                        <router-link to="/">
                          <v-icon>
                            fas fa-location-arrow
                          </v-icon>
                          مراسلة العميل
                        </router-link>
                      </v-list-item-title>
                    </v-list-item> -->
                    <v-list-item v-if="permissons.edit_price.read || permissons.edit_price.update ||permissons.edit_price.add ">
                      <v-list-item-title>
                        <router-link :to="'/Accountant-Treatment/'+ item.id ">
                          <v-icon>
                            fas fa-money-bill
                          </v-icon>
                          تعديل السعر
                        </router-link>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item v-if="permissons.create_transaction.remove">
                      <v-list-item-title>
                        <a @click="handleDeleteTransactionDialog(item.id, item.transaction_id, )">
                          <v-icon>
                            far fa-trash
                          </v-icon>
                          حذف
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                    <v-list-item>
                      <v-list-item-title>
                        <a @click="reassign(item)">
                          <v-icon>
                            mdi-account-network-outline
                          </v-icon>
                          إعادة اسناد
                        </a>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </div>
            </template>
          </template>
        </v-data-table>
      </template>
    </v-card>
    <v-dialog v-model="deleteTransactionDialog.show">
      <v-card>
        <v-card-title class="text-h5">
          {{ deleteTransactionDialog.title }}
        </v-card-title>

        <v-card-text>
          {{ deleteTransactionDialog.body }}
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            color="red darken-1"
            text
            @click="deleteTransactionDialog.show = false"
          >
            إلغاء
          </v-btn>

          <v-btn
            :color="deleteTransactionDialog.saveButton.color"
            :class="deleteTransactionDialog.saveButton.class"
            text
            @click="deleteTransactionDialog.saveButton.action"
          >
            <v-icon v-if="deleteTransactionDialog.saveButton.icon !== ''">
              {{ deleteTransactionDialog.saveButton.icon }}
            </v-icon>
            {{ deleteTransactionDialog.saveButton.text }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="errorSnackbar"
      color="red"
      bottom
      left
      :timeout="timeout"
    >
      {{ errorMessage }}
    </v-snackbar>
    <v-snackbar
      v-model="successSnackbar"
      color="green"
      bottom
      left
      :timeout="timeout"
    >
      {{ successMessage }}
    </v-snackbar>
    <reassign-dialog
      v-model="reassignDialog"
      :users-list="usersList"
      :reassign-data="reassignData"
      @load="() => fetchAllItems()"
    />
  </v-container>
</template>

<script>
  import { ServiceFactory } from '../../../services/ServiceFactory'
  /**
   * ? static data
   */
  import defaultValuesForPdf from './defaultValuesForPdf'
  /**
   * * third library
   */
  import qs from 'qs'
  /**
   * ? services
   */
  const TransactionsServices = ServiceFactory.get('Transactions')
  const CustomersService = ServiceFactory.get('Customers')
  const RegionsServices = ServiceFactory.get('Regions')
  const CitesServices = ServiceFactory.get('Cites')
  const NeighborhoodsServices = ServiceFactory.get('Neighborhoods')
  const PropertyRatingsServices = ServiceFactory.get('PropertyRatings')
  const PropertyTypesServices = ServiceFactory.get('PropertyTypes')
  const EvaluationPurposeService = ServiceFactory.get('EvaluationPurpose')
  const UsersServices = ServiceFactory.get('Users')
  const SettingService = ServiceFactory.get('Setting')
  const ReportTypesServices = ServiceFactory.get('ReportTypes')
  const constructionConditionsService = ServiceFactory.get('constructionConditions')

  export default {
    name: 'NewTreatment',
    components: {
      // VueHtml2pdf: () => import('vue-html2pdf'),
      // PdfContent: () => import('./Pdf/PdfContent.vue'),
      // CustomProgress: () => import('../component/progress.vue'),
      SelectSample: () => import('./SelectSample.vue'),
      ReassignDialog: () => import('../component/Transactions/ReassignDialog.vue'),
      // PdfContentAnother: () => import('./Pdf/PdfContentAnother.vue'),
      // PdfFrench: () => import('./Pdf/pdfFrench.vue'),
    },
    props: {
      type: { type: Number, required: false, default: 0 },
      status: { type: Array, required: false, default: () => ([]) },
    },
    data: () => ({
      errorSnackbar: false,
      successSnackbar: false,
      successMessage: '',
      timeout: 3000,
      errorMessage: '',
      progressNumber: 0,
      showProgress: false,
      deleteTransactionDialog: {
        show: false,
        title: '',
        extraData: { transactionId: '' },
        body: '',
        saveButton: {
          text: 'تأكيد',
          color: '',
          class: 'ma-2 light-green-btn',
          icon: '',
          action: '',
        },
      },
      permissons: {
        edit_price: {},
        create_transaction: {},
      },
      /**
       * ? pdf data
       */
      pdfData: {
        ...defaultValuesForPdf,
      },
      styleData: {
        'background-color': '#187F7B',
        'border-color': '#ccc',
        color: '#fff',
      },
      styleSubData: {
        'background-color': '#F3F5F5',
        'border-color': '#ccc',
        color: '#000',
      },
      /**
       * ? component data
      */
      data: {
        region_id: '',
        customer_id: '',
        neighborhood_id: '',
        property_type_id: '',
        property_rating_id: '',
        evaluation_purpose_id: '',
        reviewer_id: '',
        resident_id: '',
        status: [],
        order_by: [],
        dateFrom: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        dateTo: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      },
      // lists for dialot
      evaluationPurposeList: [],
      customersList: [],
      regionsList: [],
      citesList: [],
      neighborhoodsList: [],
      propRatingsList: [],
      propTypeList: [],
      usersList: [],
      ResidentesList: [],
      ReviewersList: [],
      statuses: {
        1: 'مسودة',
        2: 'تحت التقييم',
        3: 'تحت المراجعة',
        4: 'قيد الاعتماد',
        5: 'معتمدة',
        6: 'مرسلة',
        7: 'معلقة',
        8: 'ملغية',
      },
      samplesList: [],
      search: '',
      isLoading: false,
      page: 1,
      total: 0,
      numberOfPages: 0,
      options: { itemsPerPage: 20 },
      loading: false,
      deleteDailog: false,
      userDetails: {},
      // Checkboxes
      ex1: false,
      ex2: false,
      ex3: false,
      ex4: false,
      ex5: false,
      ex6: false,
      ex7: false,
      ex8: false,
      ex9: false,
      ex10: false,

      // Dialogs
      dialog: false,
      dialogOpen: false,
      reassignDialog: false,
      reassignDialogOpen: false,
      reassignData: {},
      // Selectboxes
      items: [
        'Item 1',
        'Item 2',
        'Item 3',
        'Item 4',
      ],
      // Date Inputs
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      menu1: false,
      menu2: false,
      // Table
      headers: [
        { text: 'رقم المعاملة', value: 'transaction_id', sortable: false },
        { text: 'رقم الصك', value: 'trans_instrument_num', sortable: false },
        { text: 'اسم العميل', value: 'customer.cs_name', sortable: false },
        { text: 'تم التوجيه بواسطة', value: 'addby.name', sortable: false },
        { text: 'المقيم', value: 'resident.name', sortable: false },
        { text: 'نوع العقار', value: 'propertytype.name', sortable: false },
        { text: 'المدينة', value: 'city.name', sortable: false },
        { text: 'الحالة', value: 'status', sortable: false },
        { text: 'خيارات', value: 'action', sortable: false },
      ],
      itemsTr: [
      ],
    }),
    computed: {
      updateCitesList: function () {
        // const citesList = [];
        const data = this.citesList.filter((city) => {
          if (city.regionId === this.data.region_id) {
            return city
          }
        })
        return data
      },
      updateNeighborhoodsList: function () {
        const data = this.neighborhoodsList.filter((neighborhood) => {
          if (neighborhood.cityId === this.data.city_id) {
            return neighborhood
          }
        })
        return data
      },
    },
    watch: {
      options: {
        handler ({ page: oldPage }, { page: newPage }) {
          // console.log(oldPage)
          // console.log(newPage)
          if (oldPage !== newPage) {
            this.fetchAllItems({ page: oldPage, type: this.type, status: this.status })
          }
        },
        deep: true,
      },
      dialog: {
        handler () {
          // only fetch the data one time
          if (!this.dialogOpen) {
            this.getCustomers()
            this.getRegions()
            this.getCites()
            this.getNeighborhoods()
            this.getPropertyTypes()
            this.getPropertyRatings()
            this.getEvaluationPurpose()
            this.getAllUsers()
            this.dialogOpen = true
          }
        },
      },
      reassignDialog: {
        handler () {
          // only fetch the data one time
          if (!this.reassignDialogOpen) {
            this.getAllUsers()
            this.reassignDialogOpen = true
          }
        },
      },
    },
    created () {
      // console.log(this.$route)
    },
    mounted () {
      this.permissons.edit_price = this.can('تعديل السعر')
      this.permissons.create_transaction = this.can('مرحلة الادخال')
      this.options = { page: 1, itemsPerPage: 20 }
      // this.fetchAllItems({ type: this.type, status: this.status })
      this.getConstructionCondition()
    },
    methods: {
      reassign (data) {
        this.reassignDialog = true
        this.reassignData = data
      },
      // eslint-disable-next-line camelcase
      isFrenchIndv (sample_name) {
        if (sample_name.includes('فرنسي') || sample_name.includes('فرنسى')) {
          if (sample_name.includes('افراد') || sample_name.includes('أفراد')) {
            return true
          }
        }
        return false
      },
      // eslint-disable-next-line camelcase
      isFrenchComp (sample_name) {
        if (sample_name.includes('فرنسي') || sample_name.includes('فرنسى')) {
          if (sample_name.includes('شركات')) {
            return true
          }
        }
        return false
      },
      getSampleComponent (item) {
        // eslint-disable-next-line camelcase
        const sample_name = item.sample.name
        if (this.isFrenchIndv(sample_name)) {
          return 'frenchSample'
        } else if (this.isFrenchComp(sample_name)) {
          return 'frenchSampleComp'
        } else if (item.status !== 1 && item.statusWhenSuspended !== 1) {
          return 'EvaluateTreatment'
        } else {
          return 'NewTreatment'
        }
      },
      getFacility: async function () {
        const { data } = await SettingService.getFacility()
        return data
      },
      getReportType: async function (id) {
        const { data: { name } } = await ReportTypesServices.fetchOneItem(id)
        return name
      },
      filterTransctions: async function () {
        const data = { ...this.data }
        for (const key in data) {
          if (!data[key]) {
            delete data[key]
          }
        }
        history.replaceState(null, null, '?' + qs.stringify({ ...data, page: this.page }))
        // console.log(qs.stringify(data))
        // this.$router.replace({ query: data })
      },
      fetchAllItems: async function (options) {
        this.isLoading = true
        let _options = { ...options } || {}
        if (this.$route.path === '/Treatments') {
          _options = { ..._options, ...qs.parse(window.location.search.substr(1)) }
        } else {
          const { page } = this.options
          _options.page = page
        }

        const items = await TransactionsServices.getAllItems(_options)
        // console.clear()
        if (this.$route.path === '/') {
          const { type, status } = _options
          switch (type) {
            case 1 :
              this.$store.dispatch('setTotal', { type: 'underEvaluation', total: items.total })
              break
            case 2 :
              this.$store.dispatch('setTotal', { type: 'underReview', total: items.total })
              break
            case 3 :
              if (status[0] === 4) {
                this.$store.dispatch('setTotal', { type: 'underApproval', total: items.total })
              } else {
                this.$store.dispatch('setTotal', { type: 'approvaed', total: items.total })
              }
              break
            case 4:
              this.$store.dispatch('setTotal', { type: 'added', total: items.total })
              break
            default:
              break
          }

          // if there is no type
          if (!_options.type && _options.status) {
            switch (status) {
              case 7:
                this.$store.dispatch('setTotal', { type: 'suspended', total: items.total })
                break
              default:
                break
            }
            // console.log('I have only status, not type', _options.status)
          }
        }
        /**
         */
        // items.data.forEach(item => {
        //   item.status = this.statuses[item.status]
        // })
        this.itemsTr = items.data
        this.total = items.total
        this.isLoading = false
      },
      getCustomers: async function () {
        const { data } = await CustomersService.getAllItems(1000, 1)
        this.customersList = data.data.map((customer) => {
          return ({
            id: customer.id,
            name: customer.cs_name,
          })
        })
      },
      // Placess
      getRegions: async function () {
        const { data } = await RegionsServices.getAllItems()
        this.regionsList = data.data.map(({ id, name }) => ({
          id, name,
        }))
      },
      getCites: async function () {
        const { data } = await CitesServices.getAllItems()
        this.citesList = data.data.map((city) => ({
          id: city.id,
          name: city.name,
          regionId: city.region_id,
        }))
      },
      getNeighborhoods: async function () {
        const { data } = await NeighborhoodsServices.getAllItems()
        this.neighborhoodsList = data.data.map((neighborhood) => ({
          id: neighborhood.id,
          name: neighborhood.name,
          cityId: neighborhood.city_id,
        }))
      },
      // property ratings
      getPropertyRatings: async function () {
        const { data } = await PropertyRatingsServices.getAllItems()
        this.propRatingsList = data.data.map((pr) => {
          return {
            id: pr.id,
            name: pr.name,
          }
        })
      },
      // property types
      getPropertyTypes: async function () {
        const { data } = await PropertyTypesServices.getAllItems()
        this.propTypeList = data.data.map((pt) => {
          return {
            id: pt.id,
            name: pt.name,
          }
        })
      },
      getConstructionCondition: async function () {
        const { data } = await constructionConditionsService.getAllItems()
        this.transConstructionList = data.data.map(({ id, name }) => ({
          id: String(id), name,
        }))
      },
      getEvaluationPurpose: async function () {
        const { data } = await EvaluationPurposeService.getAllItems()
        this.evaluationPurposeList = data.data.map(({ id, name }) => ({
          id, name,
        }))
      },
      getAllUsers: async function () {
        const { data: { data } } = await UsersServices.getAllItems()
        this.usersList = Object.freeze(data.map(({ id, name }) => ({ id, name })))
      },
      getResidentes: async function () {
        const { data } = await UsersServices.getByType(3)
        this.ResidentesList = data.map(({ id, name }) => ({
          id, name,
        }))
      },
      getReviwers: async function () {
        const { data } = await UsersServices.getByType(4)
        this.ReviewersList = data.map(({ id, name }) => ({
          id, name,
        }))
      },
      handleDeleteTransactionDialog: function (transactionId, transactionNumber) {
        this.deleteTransactionDialog.show = true
        this.deleteTransactionDialog.title = 'تأكيد الحذف'
        this.deleteTransactionDialog.body = `هل أنت متأكد من حذف المعاملة "${transactionNumber}" ؟`
        this.deleteTransactionDialog.saveButton.action = () => { this.deleteTransaction(transactionId); this.deleteTransactionDialog.show = false }
      },
      deleteTransaction: async function (id, index) {
        this.isLoading = true
        try {
          const { success } = await TransactionsServices.deleteOneItem(id)
          if (success === true) {
            this.itemsTr = this.itemsTr.filter(item => item.id !== +id)
            this.successSnackbar = true
            this.successMessage = 'تم حذف المعاملة بنجاح'
          }
        } catch {
          this.errorSnackbar = true
          this.errorMessage = 'حدثت مشكلة أثناء الحذف برجاء المحاولة وقت لاحق'
        } finally {
          this.isLoading = false
        }
      },
    },
  }
</script>

<style lang="scss" scoped>
.filter-bg{
    background-color: #5FCA21 !important;
}
label{
    font-size: 13px;
}
.card-title{
  color: #37A8FF
}

.number_link {
  color:#3772ff !important;
}
a{
  text-decoration: none;
  color: #000
}

.light-green-btn{
  background-color: #4DC334 !important;
  color: #fff !important;
}

.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr:last-child > th{
  color: #000;
  font-size: 15px;
  white-space: nowrap;
  font-weight: 500;
}
.elevation-1 th,
.theme--light.v-data-table > .v-data-table__wrapper > table > thead > tr > th{
  color: #000 !important
}
.elevation-1 th span{
  font-size: 25px !important;
}

// .elevation-1 .v-data-footer__select {
//   display: none !important;
// }
</style>

<style>
  .elevation-1 .v-data-footer__select {
    visibility: hidden;
  }
  .v-dialog {
      scrollbar-color: #3772FF rgba(0, 0, 0, 0.1);
    scrollbar-width: thin;
}
.v-dialog #cancel-filter i {
  transform: translateX(6px);
}

.v-dialog::-webkit-scrollbar {
    background: rgba(0, 0, 0, 0.3);
    width: 8px;
    border-radius: 5px;
}

.v-dialog::-webkit-scrollbar-thumb {
    background: #3772FF ;
    border-radius: 5px;
}

</style>
